import React from 'react';
import './App.css';
import Button from '@material-ui/core/Button';

const colCount = 24;
const rowCount = 24;



class App extends React.Component{
  constructor(props) {
    super(props);
    var cellData = new Array(rowCount)
    for (let i = 0; i < rowCount; i++) {
      cellData[i] = new Array(colCount);
      for (let j = 0; j < colCount; j++) {
        cellData[i][j] = 0;
      }
    }
    this.handleCellClick = this.handleCellClick.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);

    cellData[1][1] = 1;
    cellData[3][7] = 1;
    cellData[3][8] = 1;
    cellData[3][6] = 1;
    cellData[3][5] = 1;
    cellData[3][4] = 1;
    cellData[2][5] = 1;
    cellData[1][7] = 1;
    cellData[5][7] = 1;
    cellData[3][7] = 1;
    this.state = {
      cellData: cellData,
      isPlaying: false
    };
  }

  handleCellClick(row, col)
  {
    let newCellData = this.state.cellData;
    if(newCellData[row][col] > 0)
    {
      newCellData[row][col] = 0;
    }
    else
    {
      newCellData[row][col] = 1;
    }
    this.setState({cellData: newCellData});
  }

  handleOnClick(e)
  {
    if(this.state.isPlaying)
    {
      if(this.interval !== undefined)
      {
        clearInterval(this.interval);
      }
      this.setState({isPlaying: false})
    }
    else
    {
      this.interval = setInterval(() => {

        //let newCellData = [...this.state.cellData];


        let newCellData = [];

        for (let i = 0; i < this.state.cellData.length; i++) {
          let newRow = []
          for (let j = 0; j < this.state.cellData[i].length; j++) {
            newRow.push(this.state.cellData[i][j]);
          }
          newCellData.push(newRow);
        }

        for (let i = 1; i < newCellData.length - 1; i++) {
          for (let j = 1; j < newCellData[i].length - 1; j++) {
            let count = 0;

            for (let k = -1; k < 2; k++) {
              for (let l = -1; l < 2; l++) {
                if(this.state.cellData[i + k][j + l] > 0)
                {
                  count++;
                }
              }
            }
            if(this.state.cellData[i][j] > 0)
            {
              count--;
            }

            if(count < 2)
            {
              newCellData[i][j] = 0;
            }
            else if(count === 3)
            {
              newCellData[i][j] = 1;
            }
            else if(count > 3)
            {
              newCellData[i][j] = 0;
            }
          }
        }
        this.setState({ cellData: newCellData })
      }, 200);
      this.setState({isPlaying: true})
    }
  }

  componentDidMount() {

  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render()
  {
    return (
        <div className="App">
          <Table row={rowCount} col={colCount} cellData={this.state.cellData} onCellClick={this.handleCellClick}></Table>
          <Button onClick={this.handleOnClick}>{this.state.isPlaying ? 'Pause' : 'Play'}</Button>
        </div>
    );
  }
}

class Table extends React.Component{
  render() {
    let data = [];
    for (let i = 1; i < this.props.row - 1; i++) {
      data.push(<Row col={this.props.col} row={i} cellData={this.props.cellData} onCellClick={this.props.onCellClick}></Row>);
    }

    return (<table>{data}</table>);
  }
}

class Row extends React.Component{
  render() {
    let data = [];
    for (let i = 1; i < this.props.col - 1; i++) {
      data.push(<Cell col={i} row={this.props.row} cellData={this.props.cellData} onCellClick={this.props.onCellClick}></Cell>);
    }
    return (<tr>{data}</tr>);
  }
}

class Cell extends React.Component{

  constructor(props) {
    super(props);
    this.handleOnCellClick = this.handleOnCellClick.bind(this);
  }

  handleOnCellClick(e) {
    this.props.onCellClick(this.props.row, this.props.col);
  }

  render() {
    const style = {
      backgroundColor: 'lightgrey'
    };
    if(this.props.cellData[this.props.row][this.props.col] > 0)
    {
      style.backgroundColor = 'grey'
    }
    return (<td style={style} onClick={this.handleOnCellClick}>&nbsp;</td>);
  };
}

export default App;
